//
// Form validation
//

// Validation

.has-success,
.has-danger {
    position: relative !important;
    border-color: $input-focus-border-color !important;

    &:after {
        width: 19px !important;
        height: 19px !important;
        line-height: 19px !important;
        text-align: center !important;
        font-family: "NucleoIcons" !important;
        display: inline-block !important;
        position: absolute !important;
        right: 15px !important;
        top: 2px !important;
        transform: translateY(50%) !important;
        border-radius: 50% !important;
        font-size: 9px !important;
        opacity: 1 !important;
    }
}

.has-success {
    &:after {
        content: "\ea26" !important;
        color: daken($form-feedback-valid-color, 18%) !important;
        background-color: $form-feedback-valid-bg !important;
    }

    .form-control {
        background-color: $input-focus-bg !important;

        &:focus {
            border-color: $input-focus-border-color !important;
        }

        // Placeholder

        &::placeholder {
            color: $form-feedback-valid-color !important;
        }
    }
}

.has-danger {
    &:after {
        content: "\ea53" !important;
        color: daken($form-feedback-invalid-color, 18%) !important;
        background-color: $form-feedback-invalid-bg !important;
    }

    .form-control {
        background-color: $input-focus-bg !important;

        &:focus {
            border-color: $input-focus-border-color !important;
        }

        // Placeholder

        &::placeholder {
            color: $form-feedback-invalid-color !important;
        }
    }
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
