.navprofile {
    padding-right: 32px;
}
.navbar-top {
    background-color: #edeef2 !important;
}
.maximum-width {
    max-width: 230px;
}
.maximum-width-company {
    max-width: 200px;
}

.navbar > .container,
.navbar > .container-fluid {
    flex-wrap: wrap !important;
    justify-content: flex-start !important;
    flex-direction: row !important;
    align-items: center !important;
}
/* @media only screen and (min-width: 1490px) { */
    /* .button-div { */
        /* margin-top: -45px; */
        /* position: absolute; */
    /* } */
/* } */

@media only screen and (min-width: 1580px) {
    .btn-grp {
        position: relative !important;
        top: -35px !important;
    }
}
@media only screen and (min-width: 768px) {
    .navbar-vertical.navbar-expand-md > [class*="container"] {
        flex-direction: column;
        align-items: stretch;
        min-height: 30% !important;
        padding-left: 0;
        padding-right: 0;
        padding-top: 20px !important;
      }
}
@media only screen and (max-width: 426px) {
    .content {
        margin-top: 10px !important;
    }
    .main-content .navbar-top {
        display: none;
    }
}
