body {
  background-color: #edeef2;
}

.taskbox {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 30px;
  flex-wrap: wrap;
  margin: 0;
  margin-bottom: 20px;
}

.ck-progress-bar {
  background-color: green !important;
  height: 5px !important;
  top: auto !important;
  bottom: 0;
}

.ck {
  color: black
}

.ck > p {
line-height: 1.3;
}

.ck > h1 {
  line-height: 0;
}


/* .task {
  border: 1px rgba(199, 195, 195, 0.577) solid;
  padding: 30px;
  background-color: white;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  border-radius: 6px;
} */
.taskboxone {
  margin-top: 0;
  margin-bottom: 0 !important;
  flex-grow: 1;
  width: unset;
  padding: 30px;
  border: 1px solid rgba(199, 195, 195, 0.577);
  border-radius: 6px;
  background-color: white;
}
.taskboxone input, .taskboxone textarea {
  width: 100%;
  background: transparent;
  border-radius: 6px;
}
.taskboxone textarea {
  min-height: 350px;
}

.title:last-of-type {
  margin-bottom: 0;
}

.inputfield {
  padding: 6px
}

.submitbtn {
  /* all: unset; */
  cursor: pointer;
  border: none;
  color: white;
  background-color: rgba(0, 0, 255, 0.69);
  /* background-color: #b2dd4c; */
  border-radius: 6px;
  height: 30px;
  width: 80px;
  /* margin-top: 20px; */
  margin-left: 40px;
}

input {
  width: 300px;
  margin: 40px;
  padding: 20px;
  background-color: #edeef2;

  border: 1px rgba(199, 195, 195, 0.577) solid;
}

textarea {
  width: 300px;

  padding: 20px;
  background-color: #edeef2;
  overflow: auto;
  resize: none;
  margin-top: 30px;
  border: 1px rgba(199, 195, 195, 0.577) solid;
}

textarea::-webkit-scrollbar-track {
  /* 	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
  background-color: #edeef2;
}

textarea::-webkit-scrollbar {
  width: 6px;
  /* background-color: #F5F5F5; */
}

textarea::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #000000a2;
}

.detailbox {
  border: 1px rgba(199, 195, 195, 0.577) solid;
  border-radius: 6px;
  padding: 20px;
  width: 260px;
  background-color: white;
	position: fixed;
	left: auto;
	right: 30px;
	height: 68%;
}

.detailbox > hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.details > div > p {
  font-weight: 300;
  font-size: 1rem;
  margin-bottom: 0px;
  line-height: 1.7;
  color: #525f7f;
}

@media screen and (max-width: 768px) {
	.detailbox {
		height: 79%;
	}
}

@media screen and (min-width: 600px) {
  .taskboxone {
    padding: 20px;
    max-width: calc(100% - 290px);
  }
}
@media screen and (max-width: 600px) {
  .task {
    padding: 17px;
  }

	.comment-list {
		max-width: 100% !important;
	}

  .submitbtn {
    margin-left: 0px;
  }
  .taskbox {
    margin-left: 0px;
    margin-right: 0px;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
  }
  .taskboxone {
    padding: 20px;
    width: 100%;
  }
  .detailbox {
    width: 100%;
    margin-left: 30px;
		position: relative;
  }
  details > div > p {
    font-weight: 400;
    font-size: 20px;
  }
  input {
    margin: 10px;
  }

  textarea {
    margin: 10px;
  }
}

.details > div > select, .details input {
  cursor: pointer;
  cursor: pointer;
  padding: 0.5rem;
  background-color: transparent;
  border: 1px solid rgba(199, 195, 195, 0.577);
  border-radius: 6px;
}

.details > div {
  display: flex;
  flex-direction: column;
  margin: 20px 0px;
}

.selectOptions {
  display: flex;
  position: relative;
  height: 50px;
}
.selectOptions > input {
  width: 100%;
  height: 40px;
  border-bottom: unset;
  border-right: unset;
  border-left: unset;
}
.selectOptions > select {
  width: 100%;
  position: absolute;
  border: none;
  bottom: -10px;
  background: transparent;
  right: -2px;
}

.comment-section {
  margin-top: 20px;
}

.comment-list {
  margin-bottom: 20px;
	max-width: calc(100% - 290px);
}
.comment-item {
  background-color: #f9f9f9;
  border-radius: 10px;
  margin-bottom: 20px;
  overflow: hidden;
}

.comment-text {
  color: #666666;
  margin: 0;
}

.comment-buttons span {
  margin-left: 10px;
  cursor: pointer;
}

.comment-buttons span:hover {
  text-decoration: underline;
}

.comment {
  margin-bottom: 20px;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.comment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
}

.username {
  font-weight: bold;
  margin-bottom: 5px;
  color: #000;
	font-weight: bold;
}

.comment-buttons {
  display: flex;
  align-items: center;
}

.edit-comment,
.delete-comment {
  cursor: pointer;
  margin-left: 10px;
  color: #000;
  font-weight: bold;
  opacity: 0.7;
}

.edit-comment:hover,
.delete-comment:hover {
	cursor: pointer;
	color: #b2dd4c;
	text-decoration: none !important;
}

.comment-body {
  margin-top: 10px;
  margin-left: 25px;
}

.comment-image img {
  max-width: 300px;
  object-fit: contain;
  height: 100%;
}

.commentBox {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  background-color: #f9f9f9;
  max-width: calc(100% - 290px);
}

.commentBox .ckEditor {
  border: none;
  border-radius: 5px;
  background-color: #fff;
  padding: 10px;
}

.updateTaskButton {
  margin-right: 10px;
}

.save-edit-btn {
  margin: 10px 0 10px 15px;
}