.week-container {
  margin-top: 25px;
  position: relative;
}

.week-header {
  display: flex;
  border-bottom: 1px solid #ccc;
  padding-bottom: 0.5rem;
  text-align: center;
}

.week-sub-header {
  width: 100%;
  display: flex;
  text-align: center;
  font-weight: bold;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
}

.week-body {
  margin-top: 8px;
}

.week-row {
  display: flex;
  align-items: center;
  position: relative;
  height: 40px;
}

.week-day {
  width: 50px;
  font-weight: 500;
}

.week-bar-container {
  width: 100%;
  background-color: #e6e8e9;
  border-radius: 5px;
  position: relative;
  height: 24px;
  cursor: pointer;
  overflow: hidden;
}

.week-bar {
  position: absolute;
  top: 0;
  background-color: #28a745;
  height: 100%;
  border-radius: 5px;
  /* width: 0%;
  transition: width 0.5s ease-in-out; */
}

.week-tooltip {
  position: absolute;
  right: 0;
  /* transform: translateX(-50%); */
  margin-top: 0.5rem;
  background-color: white;
  color: black;
  font-size: 0.875rem;
  border-radius: 5px;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
}

.week-tooltip > p {
  margin: 0;
  padding: 6px 15px;
}

.week-tooltip > p:nth-child(3) {
  margin: 0;
  padding-bottom: 0px;
}

.week-tooltip > hr {
  margin: 0;
}
