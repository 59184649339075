.table {
  & td, & th {
    white-space: unset;
  }
  .avatar-group .avatar
  {
    margin-right: 4px;
  }
  .badge i{
    margin-right: 10px;
  }
}

.timeContainer {
  position: absolute;
  right: 50px;
  top: 18px;
}
.cursor {
  cursor: pointer;
}
.image-item {
  width: 100%;
  height: 150px;
  display: inline-block;
}

.img {
  width: 100%;
  object-fit: contain;
  height: 150px;
}
.image-container {
  cursor: pointer;
  margin: 0 10px;
}
.ReactModal__Overlay {
  z-index: 9999 !important;
}

.slick-track {
  margin-left: 0;
  margin-right: 0;
}
.slick-prev,
.slick-next {
  &:before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 700;
    color: #000;
    font-size: 25px;
  }
}
.slick-prev {
  border-color: #ADB1B8 #A2A6AC #8D9096;
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  background: white;
  padding: 1px;
  width: 30px;
  height: 30px;
  left: 2px;
  z-index: 9;
  border-radius: 100%;
  &::before {
    content: "\f104";
  }
}
.slick-next {
  border-color: #ADB1B8 #A2A6AC #8D9096;
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  background: white;
  padding: 10px;
  padding: 1px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  right: 2px;
  z-index: 9;
  &::before {
    content: "\f105";
  }
}

.loader-container {
  .block-ui {
    display: flex;
    min-height: 100vh;
  }
}
@media (max-width: 768px) {
  .member-select {
    justify-content: flex-start !important;
  }
  .sub-header {
    display: flex !important;
    flex-direction: column !important;
  }
    
}
.sub-header > div {
  width: 100% !important;
}

.auth-app-logo {
  max-width: 180px;
  padding-bottom: 28px;
}

.fc .fc-view-harness {
  height: 100vh !important;
}

.fc .fc-list-event-graphic, .fc .fc-list-event-time {
  display: none !important;
}