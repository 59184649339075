.custom-select {
  position: relative;
  width: 300px;
  font-size: 16px;
  z-index: 99;
}

.select-header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.clear-selection-icon {
  position: absolute;
  right: 25px;
  top: 8px;
  cursor: pointer;
}
.option-render {
  height: 20px;
  background: #8080804f;
  max-width: 90px;
  font-size: 12px;
  padding: 1px 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 3px;
}
.select-header input[type="text"] {
  flex-grow: 1;
  margin-right: 5px;
  border: none;
  outline: none;
}

.select-header .clear-selection {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #fff;
  background-color: #999;
  cursor: pointer;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.select-header .clear-selection:hover {
  background-color: #777;
}

.select-options {
  position: absolute;
  top: 100%;
  left: 15px;
  right: 15px;
  overflow: hidden;
  max-height: 300px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  -ms-border-radius: 0 0 4px 4px;
  -o-border-radius: 0 0 4px 4px;
  z-index: 1;
}
.all-option-container {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
}

.select-options-header {
  display: flex;
  align-items: center;
  padding: 5px;
  border-bottom: 1px solid #ccc;
  height: 40px;
}

.select-options-header input[type="text"] {
  flex-grow: 1;
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
  padding: 5px;
}

.select-options-header .react-icons {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.select-option {
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.select-option input[type="checkbox"] {
  margin-right: 5px;
  cursor: pointer;
}

.select-option label {
  cursor: pointer;
}

.select-option:hover {
  background-color: #f5f5f5;
}
.custom-select {
  padding: 0.3rem 0.75rem !important;
  font-size: 0.875rem !important;
}
.dropdown-select-option {
  display: flex;
}
.dropdown-select-option input[type="checkbox"] {
  width: auto !important;
}
.assignee-dropdown > div > div{
  max-height: fit-content !important;
}
