.nousers {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  overflow: auto;
}

.nodata {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.row {
  margin-bottom: 3px;
}
.table-border {
  border: 1px solid #ccc;
  border-bottom: 0;
  width: 100%;
  align-items: center;
}

.table-border div {
  padding: 5px 0;
  padding-left: 8px !important;
}

@media (min-width: 1024px) {
  .table-border {
    display: contents !important;
  }
}
.table-border {
  margin-bottom: 0px !important;
}
.table-border:last-child {
  border-bottom: 1px solid #ccc;
}

.card-table-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.chart-card {
  padding: 0 15px !important;
}
@media (max-width: 769px) {
  .table-border {
    /* display: block !important; */
    border: 0;
  }
  .hide_from_reports {
    margin-left: 0 !important;
  }
  .header-dashboard-section {
    border-bottom: 1px solid #ccc;
    border-right: 0;
  }
}

@media screen and (max-width: 1080px) {
  .topProjectsCard {
    height: 48vh !important;
  }
  .screensm {
    height: 45vh !important;
  }
}

@media screen and (max-width: 992px) {
  .trendSm {
    height: 35vh !important;
  }
}

.scroll {
  height: 32vh;
  overflow-y: auto;
}
@media screen and (max-width: 360px) {
  .mobile-view {
    max-width: 100%!important;
   
  }
}
