body {
  background-color: #edeef2;
}

.taskbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 42px 42px 10px 42px;
  flex-wrap: wrap;
  /* height: 440px; */
  /* margin-top: 80px; */
}

/* .task {
  margin: 30px;
  border: 1px rgba(199, 195, 195, 0.577) solid;
  padding: 30px;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
} */
/* .taskboxone {
  margin-top: 5px;
} */

.title {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.react-datepicker__input-container > input {
  width: 100%;
}
.submitbtn {
  /* all: unset; */
  cursor: pointer;
  border: none;
  color: white;
  background-color: rgba(0, 0, 255, 0.69);
  /* background-color: #b2dd4c; */
  border-radius: 6px;
  height: 30px;
  width: 80px;
  /* margin-top: 20px; */
  margin-left: 40px;
  text-align: center;
}

input {
  width: 300px;
  margin: 40px;
  padding: 20px;
  background-color: #edeef2;

  border: 1px rgba(199, 195, 195, 0.577) solid;
}

textarea {
  width: 300px;

  padding: 20px;
  background-color: #edeef2;
  overflow: auto;
  resize: none;
  margin-top: 30px;
  border: 1px rgba(199, 195, 195, 0.577) solid;
}

textarea::-webkit-scrollbar-track {
  /* 	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
  background-color: #edeef2;
}

textarea::-webkit-scrollbar {
  width: 6px;
  /* background-color: #F5F5F5; */
}

textarea::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #000000a2;
}

/* .detailbox {
  border: 1px rgba(199, 195, 195, 0.577) solid;
  border-radius: 6px;
  padding: 20px;
  width: 260px;
} */

/* .details > div > p {
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 0px;
  color: black;
} */

@media screen and (max-width: 600px) {
  /* .task {
    padding: 17px;
  } */

  .submitbtn {
    margin-left: 0px;
  }
  .taskbox {
    margin-left: 0px;
    margin-right: 0px;
  }
  details > div > p {
    font-weight: 400;
    font-size: 20px;
  }
  input {
    margin: 10px;
  }

  textarea {
    margin: 10px;
  }
}

.details > div > select {
  background-color: #edeef2;
  border: none;
  cursor: pointer;
}

.details > div {
  display: flex;
  flex-direction: column;
  margin: 20px 0px;
}

.selectOptions {
  display: flex;
  position: relative;
  height: 50px;
}
.selectOptions > input {
  width: 100%;
  height: 40px;
  border-bottom: unset;
  border-right: unset;
  border-left: unset;
}
.selectOptions > select {
  width: 100%;
  position: absolute;
  border: none;
  bottom: -10px;
  background: transparent;
  right: -2px;
}
